import { useEffect } from 'react';
import { useInView, useAnimate } from 'motion/react';
import { ReactComponent as Arrow } from './right-arrow.svg';
import './Service.css';

const Service = ({
    image,
    title,
    subtitle,
    details,
    features,
    onContactClick,
    largeHeight = false,
    rightAligned = false
}) => {
    const [scope, animate] = useAnimate();
    const isInView = useInView(scope, { once: true, amount: 0.5 });

    const idAppend = title.replace(/ /g, '');
    const overlayId = 'overlay-' + idAppend;
    const subtitleId = 'subtitle-' + idAppend;
    const titleId = 'title-' + idAppend;
    const detailsId = 'details-' + idAppend;
    const featureId = 'feature-' + idAppend;
    const buttonId = 'button-' + idAppend;

    const translateX = (rightAligned ? "-" : "") + "50vw";
    const textClass = "Service-text" + (rightAligned ? " Service-rightAlign" : "");

    const imageContainerClass = "Service-imageContainer" + (largeHeight ? " Service-imageContainerLarge" : "");
    const subtitleClass = "Service-subtitle" + (rightAligned ? " Service-rightTextAlign" : "");
    const detailsClass = "Service-details" + (rightAligned ? " Service-rightTextAlign" : "");

    const imageStyle = {
        backgroundImage: 'url(' + image + ')'
    };

    useEffect(() => {
        if (isInView) {
            animate('#' + overlayId, {
                backgroundColor: ["#0f0f1f99", "#C0898900"]
            }, {
                duration: 1.6,
                ease: "linear",
                delay: 0.5
            });
            animate('#' + subtitleId, {
                opacity: [0, 1],
                x: [translateX, 0]
            }, {
                duration: 1.6,
                ease: [0.23, 0.85, 0.27, 0.98]
            });
            animate('#' + titleId, {
                opacity: [0, 1],
                x: [translateX, 0]
            }, {
                delay: 0.1,
                duration: 1.6,
                ease: [0.23, 0.85, 0.27, 0.98]
            });
            animate('#' + detailsId, {
                opacity: [0, 1],
                x: [translateX, 0]
            }, {
                delay: 0.2,
                duration: 1.6,
                ease: [0.23, 0.85, 0.27, 0.98]
            });
            features.forEach((feature, index) => {
                animate('#' + featureId + index, {
                    opacity: [0, 1]
                }, {
                    delay: 1.0 + (index * 0.15),
                    duration: 1.1,
                    ease: [0.23, 0.85, 0.27, 0.98]
                });
            });
            animate('#' + buttonId, {
                opacity: [0, 1]
            }, {
                delay: 1.4,
                duration: 1.1,
                ease: [0.23, 0.85, 0.27, 0.98]
            });
        }
    }, [animate, isInView, overlayId, subtitleId, titleId, detailsId, features, featureId, buttonId, translateX]);

    return (
        <div ref={scope} className="Service">
            <div className={imageContainerClass} style={imageStyle}></div>
            <div id={overlayId} className="Service-overlay"></div>
            <div className={textClass}>
                <div id={subtitleId} className={subtitleClass}>{subtitle}</div>
                <div id={titleId} className="Service-title">{title}</div>
                <div id={detailsId} className={detailsClass}>{details}</div>
                <div className="Service-features">
                    {features.map((feature, index) => (
                        <div
                            id={featureId + index}
                            key={"feature-" + index + idAppend}
                            className="Service-feature"
                        ><Arrow className="Service-arrow" />{feature}</div>
                    ))}
                </div>
                <button
                    id={buttonId}
                    type="button"
                    className="Service-getAQuote text-red-900 bg-white border border-red-300 focus:outline-none hover:bg-red-100 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-red-800 dark:text-white dark:border-red-600 dark:hover:bg-red-700 dark:hover:border-red-600"
                    onClick={onContactClick}
                >Get a quote</button>
            </div>
        </div>
    );
};

export default Service;
