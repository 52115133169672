import tile from './tile.jpg';
import hardwood from './hardwood.jpg';
import carpet from './carpet.jpg';
import vinyl from './vinyl.jpg';
import commercial from './commercial.jpg';
import data from './Services.json';
import Service from '../Service/Service.js';
import './Services.css';

const Services = ({
    onContactClick
}) => {
  return (
    <div className="Services">
        <Service
            image={tile}
            title={data[0].title}
            subtitle={data[0].subtitle}
            details={data[0].details}
            features={data[0].features}
            rightAligned={true}
            onContactClick={onContactClick}
        />
        <Service
            image={hardwood}
            title={data[1].title}
            subtitle={data[1].subtitle}
            details={data[1].details}
            features={data[1].features}
            onContactClick={onContactClick}
        />
        <Service
            image={carpet}
            title={data[2].title}
            subtitle={data[2].subtitle}
            details={data[2].details}
            features={data[2].features}
            rightAligned={true}
            onContactClick={onContactClick}
        />
        <Service
            image={vinyl}
            title={data[3].title}
            subtitle={data[3].subtitle}
            details={data[3].details}
            features={data[3].features}
            onContactClick={onContactClick}
        />
        <Service
            image={commercial}
            title={data[4].title}
            subtitle={data[4].subtitle}
            details={data[4].details}
            features={data[4].features}
            largeHeight={true}
            rightAligned={true}
            onContactClick={onContactClick}
        />
    </div>
  );
};

export default Services;
